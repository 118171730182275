import Vue from "vue";
import Vuex from "vuex";
import brokers from "./modules/brokers";
import accountTypes from "./modules/accountTypes";
import auth from "./modules/auth";
Vue.use(Vuex);

export default new Vuex.Store({
  actions: {},
  mutations: {
    setLinkToGo(state, link) {
      state.linkToGo = link;
    },
    isFormChangedDetect(state, boolean) {
      state.isChanged = boolean;
    },
    setSelectedTab(state, selectedTab) {
      state.selectedTab = selectedTab;
    },
    setNotification(state, notificationInfo) {
      state.notification = notificationInfo;
      setTimeout(function () {
        state.notification = null;
      }, 2000);
    },
    setCurrentMode(state, data) {
      state.currentMode = data;
    },
    changeTabsNeedUpdates(state, data) {
      state.tabsNeedUpdates = data;
    },
    setLangTabClickState(state, data) {
      state.langTabClickData = data;
    },
  },
  state: {
    linkToGo: "",
    isChanged: false,
    selectedTab: "",
    notification: null,
    currentMode: "",
    tabsNeedUpdates: false,
    langTabClickData: {},
  },
  getters: {
    getLinkToGo(state) {
      return state.linkToGo;
    },
    getIsChanged(state) {
      return state.isChanged;
    },
    getSelectedTab(state) {
      return state.selectedTab;
    },
    getNotification(state) {
      return state.notification;
    },
    getCurrentMode(state) {
      return state.currentMode;
    },
    getTabsNeedUpdates(state) {
      return state.tabsNeedUpdates;
    },
    getLangTabClickState(state) {
      return state.langTabClickData;
    },
  },
  modules: {
    brokers,
    accountTypes,
    auth,
  },
});
